import type { SearchBoxSuggestion } from "@mapbox/search-js-core";

export const searchBoxOptions = {
  types: "country,region,district,place,locality,neighborhood",
};

export const accessToken =
  /* cspell:disable-next-line */
  "pk.eyJ1IjoiYWRhbWNpcmNsZSIsImEiOiJjbTd1bDViZjIwMXN5MmxvOTgwYnY4cDVoIn0.GcJ6MxDHqVTHuuzZkmt_kw";

export interface LocationOption {
  label: string;
  value: string;
  description: string;
  originalSuggestion: SearchBoxSuggestion;
  suggestion: SearchBoxSuggestion;
}
