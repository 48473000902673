import { useMemo, useState } from "react";
import type {
  SearchBoxRetrieveResponse,
  SearchBoxSuggestion,
} from "@mapbox/search-js-core";
import { SessionToken } from "@mapbox/search-js-core";
import { useSearchBoxCore } from "@mapbox/search-js-react";
import { debounce } from "lodash";
import { useCurrentCommunityMember } from "@/react/contexts/punditUserContext";
import { accessToken, searchBoxOptions } from "@/react/helpers/mapboxHelper";
import type { LocationOption } from "@/react/helpers/mapboxHelper";

const sessionToken = new SessionToken();

export const useMapbox = (originalTextValue?: string) => {
  const [suggestions, setSuggestions] = useState<SearchBoxSuggestion[]>([]);

  const [selectedLocation, setSelectedLocation] =
    useState<SearchBoxRetrieveResponse | null>();

  const [selectedMapboxId, setSelectedMapboxId] = useState<string | null>(null);

  const currentCommunityMember = useCurrentCommunityMember();
  const language = currentCommunityMember?.locale || "en";

  const searchBoxCore = useSearchBoxCore({ accessToken });

  const debouncedSearch = useMemo(
    () =>
      debounce(async (value: string) => {
        if (value) {
          const response = await searchBoxCore.suggest(value, {
            sessionToken,
            language,
            ...searchBoxOptions,
          });
          setSuggestions(response.suggestions);
        } else {
          setSuggestions([]);
        }
      }, 300),
    [searchBoxCore, language],
  );

  const findSuggestionByMapboxId = (mapboxId: string) =>
    suggestions.find(suggestion => suggestion.mapbox_id === mapboxId);

  const handleSuggestionSelect = async (option: string) => {
    setSelectedMapboxId(option);
    try {
      const selectedSuggestion = findSuggestionByMapboxId(option);
      if (!selectedSuggestion) {
        throw new Error("Suggestion not found");
      }
      const response = await searchBoxCore.retrieve(selectedSuggestion, {
        sessionToken,
        language,
        ...searchBoxOptions,
      });
      setSelectedLocation(response);
      setSuggestions([]);
    } catch (error) {
      console.error(error);
    }
  };

  const formattedNameForSuggestion = (suggestion: SearchBoxSuggestion) =>
    [suggestion.name, suggestion.place_formatted].filter(Boolean).join(", ");

  const formattedNameForSelectedLocation = (
    location: SearchBoxRetrieveResponse,
  ) => location.features[0].properties.full_address;

  const locationOptions = suggestions.map(suggestion => ({
    label: suggestion.name,
    value: suggestion.mapbox_id,
    suggestion,
    description: formattedNameForSuggestion(suggestion),
  }));

  const displayValue = (option: LocationOption) => {
    if (option?.suggestion)
      return formattedNameForSuggestion(option.suggestion);
    return originalTextValue;
  };

  const value = useMemo(() => {
    if (selectedLocation) {
      return {
        type: "mapbox_v6",
        text: formattedNameForSelectedLocation(selectedLocation),
        data: selectedLocation,
      };
    }

    return null;
  }, [selectedLocation]);

  return {
    locationOptions,
    handleSuggestionSelect,
    selectedMapboxId,
    debouncedSearch,
    selectedLocation,
    displayValue,
    value,
  };
};
